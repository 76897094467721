<template>
  <v-container fluid class='report-card align-self-sm-stretch pa-0'>
    <v-col class='pa-0 ma-0'>
      <v-row class='pa-0 ma-0 d-flex justify-space-between'>
        <span class='report-title'> {{ $t('REGISTRATIONS_BY_OPERATOR_TITLE') }} </span>
        <v-icon
          class='mr-4'
          small
          @click='fetchWidgetContent'
        >
          mdi-reload
        </v-icon>
      </v-row>
      <v-row v-if='startDate && endDate' class='active-filters-text pa-3'>
        <span>{{ $t('FROM') }}: </span>
        <span class='ml-1'>{{ `${startDate.substring(8, 10)}/${startDate.substring(5, 7)}/${startDate.substring(0, 4)}` }}</span>
        <span class='ml-4'>{{ $t('UNTIL') }}: </span>
        <span class='ml-1'>{{ `${endDate.substring(8, 10)}/${endDate.substring(5, 7)}/${endDate.substring(0, 4)}` }}</span>
      </v-row>
      <v-divider />
    </v-col>
    <v-row class='d-flex justify-center mt-2'>
      <v-card-text v-if='hasData'>
        <v-row align='center' justify='center'>
          <apexchart
            :key='chartKey'
            ref='chart'
            type='donut'
            width='500'
            :options='chartPieOptions'
            :series='chartPieData'
          />
        </v-row>
      </v-card-text>
      <span v-else>
        {{ $t('NO_DATA') }}
      </span>
    </v-row>
    <v-row
      class='report-footer'
    >
      <v-icon
        @click='openFilters'
      >
        mdi-filter-outline
      </v-icon>
    </v-row>
    <v-dialog
      v-model='filterDialog'
      fullscreen
      hide-overlay
      transition='dialog-bottom-transition'
    >
      <v-card>
        <Filters @onFilter='saveFilter' @applyFilters='fetchWidgetContent' @close='filterDialog = false' />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    components: {
      Filters: () => import('./Filters.vue'),
    },
    data () {
      return {
        reportName: 'Cadastros por operador',
        operators: null,
        records: null,
        chartKey: 1,
        filterDialog: false,
        startDate: null,
        endDate: null,
        chartActive: true,
        operatorsFilters: [],
        hasData: true,
        chartPieOptions: {
          chart: {
            width: 500,
            type: 'donut',
          },
          labels: [],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          }],
        },
        chartPieData: [],
      };
    },
    mounted: function () {
      this.fetchWidgetContent();
    },
    methods: {
      async fetchWidgetContent () {
        this.cleanData();
        let filterOperatorsId = [];
        if (this.operatorsFilters && this.operatorsFilters.length > 0) {
          if (this.operatorsFilters[0].name === 'Todos' && this.operatorsFilters[0].account_id === -1) {
            filterOperatorsId = [];
          } else {
            filterOperatorsId = this.operatorsFilters.map((o) => o.account_id);
          }
        }
        this.filterDialog = false;
        const { data } = await axios({
          url: '/report-data',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            name: this.reportName,
            filter: {
              date_filter: this.startDate && this.endDate ? `created_at >= '${this.startDate}' and created_at <= '${this.endDate}'` : null,
              operator_filter: filterOperatorsId && filterOperatorsId.length > 0 ? `creator_id in (${filterOperatorsId.join(',')})` : null,
            },
          },
        });
        const creators = await axios({
          url: '/account-get-all',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.operators = creators.data.list;
        this.records = data && data.data ? data.data : null;
        if (this.records.length > 0) {
          this.hasData = true;
          for (let i = 0; i < this.records.length; i++) {
            const index = this.operators.findIndex((o) => o.account_id === this.records[i].creator_id);
            if (index < 0) { return; }
            this.records[i].creator_name = this.operators[index].name;
            this.chartPieData.push(parseInt(this.records[i].cadastros, 10));
            this.chartPieOptions.labels.push(this.records[i].creator_name);
          }
        } else {
          this.hasData = false;
        }
        this.chartKey++;
      },
      cleanData () {
        this.chartPieData = [];
        this.chartPieOptions.labels = [];
        this.records = null;
        this.operators = null;
      },
      removeWidget (id) {
        document.removeItem(id);
      },
      saveFilter (filter) {
        if (filter.startDate) {
          this.startDate = filter.startDate;
        }
        if (filter.endDate) {
          this.endDate = filter.endDate;
        }
        if (filter.selectedOperators) {
          this.operatorsFilters = filter.selectedOperators;
        }
      },
      openFilters () {
        this.filterDialog = true;
      },
    },
  };
</script>
<style>
  html {
   overflow: hidden;
  }
  .report-card {
    background-color: #FFFFFF;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .content-value {
    margin-top: 20vh !important;
  }
  .card-title {
    min-height: 30%;
  }
  .report-footer {
    position: absolute;
    bottom: 10vh;
    left: 2vw;
  }
  .active-filters-text {
    font-size: 13px;
  }
</style>
